import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { DATE_TIME_SHORT_FORMAT } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, {
  CONTAINED,
  SECONDARY,
  OUTLINED,
} from "../ui-core/CustomButton";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import FormDateField from "../form/FormDateField";
import { endOfDay, startOfDay } from "date-fns";
import { Card, CardActions, CardContent } from "@material-ui/core";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "DashboardFilterForChartForm",
    enableReinitialize: true,
  }),
);

DashboardFilterForChartForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  change: PropTypes.func,
  withWarehouse: PropTypes.bool,
};

function DashboardFilterForChartForm(props) {
  const { getLocalisationMessage, change } = props;

  return (
    <FlexBox
      container={16}
      style={{ gap: 14 }}
      flex={true}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <Card style={{ width: "100%" }}>
        <CardContent>
          <FlexBox flex={true} direction="column" style={{ gap: 14 }}>
            <FlexBox flex={true} style={{ gap: 14 }}>
              <FlexBox flex={true}>
                <FormDateField
                  fullWidth={true}
                  ampmInClock={true}
                  formatValue={DATE_TIME_SHORT_FORMAT}
                  hintText={getLocalisationMessage("from_date")}
                  name="fromDateTime"
                  onChange={v => change("fromDateTime", startOfDay(v))}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormDateField
                  fullWidth={true}
                  formatValue={DATE_TIME_SHORT_FORMAT}
                  hintText={getLocalisationMessage("to_date")}
                  name="toDateTime"
                  onChange={v => change("toDateTime", endOfDay(v))}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} style={{ gap: 14 }}>
              <FlexBox flex={true}>
                <FormJMAutoComplete
                  withTargetFilter={false}
                  renderOption={option => (
                    <FlexBox direction="column">
                      <span>{option.name}</span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          fontStyle: "italic",
                        }}
                      >
                        {option.value.hierarchy.map((item, index, arr) =>
                          index === arr.length - 1
                            ? item.name
                            : `${item.name} > `,
                        )}
                      </span>
                    </FlexBox>
                  )}
                  fullWidth={true}
                  name="jurisdiction"
                  label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
                />
              </FlexBox>
            </FlexBox>
            {props.withWarehouse && (
              <FlexBox flex={true} style={{ gap: 14 }}>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="warehouse"
                    hintText={props.getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage("Warehouse", "Warehouse")}
                  />
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </CardContent>

        <FlexBox justify="flex-end">
          <CardActions>
            <FlexBox justify="flex-end" style={{ gap: 14 }}>
              <CustomButton
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => {
                  props.onCancel();
                }}
              >
                {getLocalisationMessage("cancel", "Cancel")}
              </CustomButton>
              <CustomButton variant={CONTAINED} color={SECONDARY} type="submit">
                {getLocalisationMessage("apply", "Apply")}
              </CustomButton>
            </FlexBox>
          </CardActions>
        </FlexBox>
      </Card>
    </FlexBox>
  );
}

export default enhancer(DashboardFilterForChartForm);
