import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, makeStyles } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import {
  formatDateTimeToUrl,
  formatText,
  safeParseDate,
} from "../../helpers/FormatUtils";
import CustomDoughnutChart from "../charts/CustomDoughnutChart";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import AdminDashboardFilterDialogWrapper from "../../wrappers/admin/dashboard/AdminDashboardFilterDialogWrapper";

const legendLabelLocalisation = (label, getLocalisationMessage) => {
  switch (label) {
    default:
      return getLocalisationMessage(label, formatText(label));
  }
};

export function getRandomColor() {
  const l = "0123456789ABCDEF";
  let col = "#";
  for (let i = 0; i < 6; i++) {
    col += l[Math.floor(Math.random() * 16)];
  }
  return col;
}

const useStyles = makeStyles(() => ({
  total: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "@media (min-width: 1700px)": {
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
}));

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByPaymentTypeChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  api: PropTypes.string,
  title: PropTypes.string,
  filter: PropTypes.object,
};

function AdminOrderCountByPaymentTypeChartWrapper(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState();
  const doughnutRef = React.useRef(null);
  const [doughnutData, setDoughnutData] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterRefresh, setFilterRefresh] = useState(false);
  const [filter, setFilter] = useState({
    ...props.filter,
  });

  useEffect(() => {
    setFilter({ ...props.filter });
    setFilterRefresh(false);
  }, [props.filter]);

  const styles = useStyles();
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    props
      .api(filterRefresh ? filter : props.filter)
      .then(r => {
        if (r && r.data) {
          const doughnut = getValue(r, "data.result");
          setActiveIndex([]);

          setDoughnutData(
            doughnut.map(item => ({
              ...item,
              color: item.color || getRandomColor(),
            })),
          );
          setIsLoading(false);
        } else {
          setActiveIndex([]);

          setNote(props.getLocalisationMessage("no_data", "No Data"));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setActiveIndex([]);

        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
      });
  }, [props.filter, filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={doughnutRef}
    >
      <AdminDashboardFilterDialogWrapper
        open={open}
        onRequestClose={() => {
          setOpen(false);
          setFilterRefresh(false);
        }}
        initialValues={{
          jurisdiction: {
            id: filter.jurisdiction_id,
          },
          fromDateTime: safeParseDate(filter.from_date_time),
          toDateTime: safeParseDate(filter.to_date_time),
        }}
        onSubmit={v => {
          setFilter({
            jurisdiction_id: getValue(v, "jurisdiction.id"),
            from_date_time: formatDateTimeToUrl(v.fromDateTime),
            to_date_time: formatDateTimeToUrl(v.toDateTime),
          });
          setOpen(false);
          setFilterRefresh(true);
        }}
      />
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.title}
          </FlexBox>
          <FlexBox>
            <CustomButton
              color={SECONDARY}
              fullWidth={true}
              startIcon={<FilterList />}
              onClick={() => {
                setOpen(true);
                setFilterRefresh(false);
              }}
            >
              {props.getLocalisationMessage("filter", "Filter")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && doughnutRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(doughnutRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <div className="relative" style={{ position: "relative" }}>
          <CustomDoughnutChart
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            legendLabelLocalisation={legendLabelLocalisation}
            getLocalisationMessage={props.getLocalisationMessage}
            data={{
              labels: doughnutData.map(doughnut => doughnut.label),
              datasets: [
                {
                  data: doughnutData.map(doughnut => doughnut.value),
                  backgroundColor: doughnutData.map(doughnut => doughnut.color),
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              ],
            }}
            position="bottom"
          />
          <div className={styles.total}>
            <h4
              style={{
                fontSize: 30,
                fontWeight: 700,
              }}
            >
              {doughnutData
                .filter((item, index) => !activeIndex.includes(index))
                .reduce((acc, item) => acc + item.value, 0)}{" "}
            </h4>

            <h6
              style={{
                fontSize: 24,
                fontWeight: 400,
                marginTop: 13,
              }}
            >
              {props.getLocalisationMessage("in_total", "In Total")}
            </h6>
          </div>
        </div>
      )}
    </Card>
  );
}

export default enhancer(AdminOrderCountByPaymentTypeChartWrapper);
