import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getAppMarketplace,
  getAppToken,
} from "../../../shared/reducers/AppReducer";
import { getValue } from "../../helpers/DataUtils";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { updatePushItem } from "../../api/admin/AdminNotificationApi";
import FlexBox from "../ui-core/FlexBox";
import { Close, ReportProblem } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  ACT_MANAGEMENT_URL,
  ORDER_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  formatActNotificationLocalisation,
  formatActReplyNotificationLocalisation,
  formatNotificationLocalisation,
} from "../help-center/LockerPostDrawer";
import {
  getPushLog,
  updatePushLog,
} from "../../reducers/PushNotificationReducer";
import { getUserId, getUserInboundKey } from "../../reducers/ProfileReducer";
import { supabase } from "../../../shared/config/SupabaseConfig";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      marketplace: getAppMarketplace(state),
      userId: getUserId(state),
      token: getAppToken(state),
      inboundKey: getUserInboundKey(state),
      isRefresh: getPushLog(state),
      currentLanguage: getCurrentLanguage(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
      updatePushLog,
    },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

AdminPushNotifications.propTypes = {
  state: PropTypes.object,
  token: PropTypes.string,
  setLocationQuery: PropTypes.func,
  setState: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  updatePushLog: PropTypes.func,
  currentLanguage: PropTypes.string,
  isRefresh: PropTypes.bool,
  inboundKey: PropTypes.string,
  userId: PropTypes.number,
};

function AdminPushNotifications({
  getLocalisationMessage,
  setLocationQuery,
  userId,
  inboundKey,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (userId && inboundKey) {
      const handleUpdate = payload => {
        if (payload.eventType === "INSERT") {
          setData(getValue(payload, "new"));
          setOpen(true);
        }
      };

      const subscribeToChanges = () => {
        const channel = supabase
          .channel("schema-db-changes")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: `notification_${inboundKey}`,
              filter: `target_user_id=eq.${userId}`,
            },
            handleUpdate,
          )
          .subscribe();

        return channel;
      };

      subscribeToChanges();

      return async () => {
        await supabase.removeChannel(subscribeToChanges());
      };
    }
  }, [userId, inboundKey]);

  return (
    <Dialog
      modal={true}
      PaperProps={{
        style: { width: "550px" },
      }}
      open={open}
    >
      <DialogTitle style={{ padding: "16px 24px 0 24px" }}>
        <FlexBox flex={true} justify="space-between">
          <FlexBox align="center" style={{ gap: 8 }}>
            <ReportProblem style={{ color: "#FF9800" }} />
            <span>
              {getLocalisationMessage("notification", "Notification")}
            </span>
          </FlexBox>
          <FlexBox justify="flex-end">
            <IconButton
              onClick={() => {
                setOpen(false);
                setLocationQuery(fp.flow(fp.set("tabValue", "SENT")));
              }}
            >
              <Close color="error" />
            </IconButton>
          </FlexBox>
        </FlexBox>
      </DialogTitle>
      <DialogContent style={{ padding: "16px 24px 24px 24px" }}>
        <FlexBox style={{ gap: 16 }} direction="column">
          <Typography style={{ color: "#3F51B5", fontWeight: "500" }}>
            {getValue(data, "push_type") === "ACT" ||
            getValue(data, "push_type") === "ACT_REPLY"
              ? getLocalisationMessage("attention", "Attention!")
              : getValue(data, "push_type") === "NOTIFICATION"
              ? getValue(data, "subject")
              : getLocalisationMessage(
                  "order_storage_limit_in_locker_post_is_reached",
                  "Order storage limit in LockerPost is reached",
                )}
          </Typography>
          <Typography variant="body2" component="p">
            {getValue(data, "push_type") === "ACT"
              ? formatActNotificationLocalisation(
                  props.currentLanguage,
                  getValue(data, "content.username"),
                  getValue(data, "content.actNumber", ""),
                  getValue(data, "content.dispatchedDate"),
                )
              : getValue(data, "push_type") === "ACT_REPLY"
              ? formatActReplyNotificationLocalisation(
                  props.currentLanguage,
                  getValue(data, "content.username"),
                  getValue(data, "content.actNumber", ""),
                  getValue(data, "content.dispatchedDate"),
                )
              : getValue(data, "push_type") === "NOTIFICATION"
              ? getValue(data, "message")
              : formatNotificationLocalisation(
                  props.currentLanguage,
                  getValue(data, "content.warehouseName"),
                  getValue(data, "content.barcode"),
                  getValue(data, "content.dispatchedDate"),
                  getValue(data, "locker_post_address"),
                  true,
                  props.showSuccessMessage,
                  getLocalisationMessage,
                )}
          </Typography>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        {getValue(data, "push_type") === "ACT" ||
        getValue(data, "push_type") === "ACT_REPLY" ? (
          <CustomButton
            variant={OUTLINED}
            color={SECONDARY}
            onClick={() => {
              updatePushItem(getValue(data, "id"))
                .then(res => {
                  if (res) {
                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "successfully_updated",
                        "Successfully Updated",
                      ),
                    );
                    props.setLocation(ACT_MANAGEMENT_URL);
                    setOpen(false);
                    props.updatePushLog(!props.isRefresh);
                  }
                })
                .catch(error => props.showErrorMessage(error));
            }}
          >
            {getLocalisationMessage("go_to_act", "Go to ACT")}
          </CustomButton>
        ) : (
          getValue(data, "push_type") === "LOCKER_POST" && (
            <CustomButton
              variant={OUTLINED}
              color={SECONDARY}
              onClick={() => {
                props.setLocation(
                  updateQuery(ORDER_LIST_URL, {
                    orderBarcode: getValue(data, "content.barcode"),
                  }),
                );
              }}
            >
              {getLocalisationMessage("assign_to_driver", "Assign To Driver")}
            </CustomButton>
          )
        )}
        <CustomButton
          style={{ width: 100 }}
          variant={CONTAINED}
          color={SECONDARY}
          onClick={() => {
            setOpen(false);
            setLocationQuery(
              fp.flow(
                fp.set("push_log_id", getValue(data, "id")),
                fp.set("tabValue", "VIEWED"),
              ),
            );
            updatePushItem(getValue(data, "id"))
              .then(res => {
                if (res) {
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_updated",
                      "Successfully Updated",
                    ),
                  );
                  props.updatePushLog(!props.isRefresh);
                }
              })
              .catch(error => props.showErrorMessage(error));
          }}
        >
          {" "}
          {getLocalisationMessage("ok", "OK")}{" "}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(AdminPushNotifications);
