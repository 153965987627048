import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { FlightLand, FlightTakeoff } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import CustomButton, { DEFAULT, SECONDARY } from "../ui-core/CustomButton";
import {
  getInternationalOrderCountByFromServiceType,
  getInternationalOrderCountByToServiceType,
} from "../../api/admin/AdminDashboardNewApi";
import { formatText } from "../../helpers/FormatUtils";
import CustomDoughnutChart from "../charts/CustomDoughnutChart";

export function getRandomColor() {
  const l = "0123456789ABCDEF";
  let col = "#";
  for (let i = 0; i < 6; i++) {
    col += l[Math.floor(Math.random() * 16)];
  }
  return col;
}

export const legendLabelLocalisation = (label, getLocalisationMessage) => {
  switch (label) {
    default:
      return getLocalisationMessage(label, formatText(label));
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminInternationalOrderCountByServiceTypeChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
  title: PropTypes.string,
};

function AdminInternationalOrderCountByServiceTypeChartWrapper(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState();
  const doughnutRef = React.useRef(null);
  const [doughnutData, setDoughnutData] = useState([]);
  const [incoming, setIncoming] = useState(false);
  const [outgoing, setOutgoing] = useState(true);

  const [activeIndex, setActiveIndex] = useState([]);

  const apis = incoming
    ? getInternationalOrderCountByFromServiceType
    : getInternationalOrderCountByToServiceType;

  useEffect(() => {
    setIsLoading(true);
    apis(props.filter)
      .then(r => {
        if (r && r.data) {
          const doughnut = getValue(r, "data.result");
          setActiveIndex([]);
          setDoughnutData(
            doughnut.map(item => ({
              ...item,
              color: item.color || getRandomColor(),
            })),
          );
          setIsLoading(false);
        } else {
          setNote(props.getLocalisationMessage("no_data", "No Data"));
          setIsLoading(false);
          setActiveIndex([]);
        }
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setDoughnutData([]);
        setActiveIndex([]);
      });
  }, [props.filter, incoming, outgoing]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={doughnutRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox flex={true} style={{ fontWeight: 700, fontSize: 16 }}>
            {props.title}
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <CustomButton
              color={incoming ? SECONDARY : DEFAULT}
              fullWidth={true}
              startIcon={<FlightLand />}
              onClick={() => {
                setOutgoing(false);
                setIncoming(true);
              }}
            >
              {props.getLocalisationMessage("incoming", "Incoming")}
            </CustomButton>
            <CustomButton
              color={outgoing ? SECONDARY : DEFAULT}
              fullWidth={true}
              startIcon={<FlightTakeoff />}
              onClick={() => {
                setIncoming(false);
                setOutgoing(true);
              }}
            >
              {props.getLocalisationMessage("outgoing", "Outgoing")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && doughnutRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(doughnutRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <div className="relative" style={{ position: "relative" }}>
          <CustomDoughnutChart
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            legendLabelLocalisation={legendLabelLocalisation}
            getLocalisationMessage={props.getLocalisationMessage}
            data={{
              labels: doughnutData.map(doughnut => doughnut.label),
              datasets: [
                {
                  data: doughnutData.map(doughnut => doughnut.value),
                  backgroundColor: doughnutData.map(doughnut => doughnut.color),
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              ],
            }}
            position="bottom"
          />
          <div
            className="absolute-center text-center"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <h4
              style={{
                fontSize: 48,
                fontWeight: 700,
              }}
            >
              {doughnutData
                .filter((item, index) => !activeIndex.includes(index))
                .reduce((acc, item) => acc + item.value, 0)}{" "}
            </h4>

            <h6
              style={{
                fontSize: 24,
                fontWeight: 400,
                marginTop: 13,
              }}
            >
              {props.getLocalisationMessage("in_total", "In Total")}
            </h6>
          </div>
        </div>
      )}
    </Card>
  );
}

export default enhancer(AdminInternationalOrderCountByServiceTypeChartWrapper);
